export const JQ_FEATURE_FLAG_NAMES = [
  'jqp-pricing-strategy',
  'jqp-package-page-v3', // https://jira.qantas.com.au/browse/MAD-1
  'jqp-vii-voucher',
];

export const QH_FEATURE_FLAG_NAMES = [
  'qhp-ga4-events',
  'qhp-package-page-v3', // https://jira.qantas.com.au/browse/MAD-1
  'qhp-vii-voucher',
];
